@import '../theme/_variables.scss';

form {
    label.required {
        &::after {
            content: '*';
            padding-left: .1em;
            color: var(--color-first-red);
            float: right;
            display: inline-block;
        }
    }

    .invalid-feedback {
        color: var(--color-error-border);

        & > .d-block {
            display: block;
            margin-top: $half-padding;
            margin-bottom: $half-padding;
        }

        .badge {
            font-weight: bold;
            background: var(--color-error-border);
            color: var(--color-primary-text);
            padding-left: $half-padding;
            padding-right: $half-padding;
            border-radius: $half-padding;
        }
    }

    .form-group {
        margin-bottom: $standard-padding;

        > label {
            display: inline-block;
        }

        > input,
        > select,
        > textarea {
            display: block;
        }
    }

    .form-collection .form-group {
        > select,
        > button {
            display: inline;
        }

        > button {
            margin-left: $half-padding;
        }
    }
}
